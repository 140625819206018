import ActionTypes from './constants';

export default (state = [], { type, ...payload }) => {
  switch (type) {
    case ActionTypes.InitializeOrder:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
